import React from "react";
import Gallery from "../Gallery/Gallery";
import { COUNTER } from "../../utils/Constants";

import './Activities.css'

function Activities(props) {
  const commons_french = require('../../translations/common_text_fr.json');
  const commons_english = require('../../translations/common_text_en.json');
  const userLang = props.language === "french";

  const [counterAzan, setCounterAzan] = React.useState(COUNTER);
  const [counterProj, setCounterProj] = React.useState(COUNTER);
  const [counterSport, setcounterSport] = React.useState(COUNTER);

  function showMoreCardsAzan() {
    setCounterAzan(counterAzan + COUNTER);
  }

  function showMoreCardsProj() {
    setCounterProj(counterProj + COUNTER);
  }

  function showMoreCardsSport() {
    setcounterSport(counterSport + COUNTER);
  }

  return (
    <section className="activities content__section">
      <div className="eblizan">
        <h3 className="content__title">Eblizan</h3>
        <p className='content__paragraph'>
          { userLang ? commons_french.activities.paragraph_text_1 : commons_english.activities.paragraph_text_1 } <span className="content__paragraph-span_name">Etézan </span>
          { userLang ? commons_french.activities.paragraph_text_2 : commons_english.activities.paragraph_text_2 } <span className="content__paragraph-span_name">Eblizan</span>.
        </p>
        <p className='content__paragraph'>
          { userLang ? commons_french.activities.paragraph_text_3 : commons_english.activities.paragraph_text_3 }
        </p>
      </div>

      <h2 className='content__title_second'>Gallery Eblizan</h2>
      <div className='content__gallery'>
        <ul className='content__gallery_element'>
          {
            <Gallery
              album={props.albumEblizan}
              count={counterAzan}
              name="azan"
              handleCardClick={(card) => props.handleCardClick(card)}
            />
          }
        </ul>
        {
          counterAzan < props.albumEblizan?.length &&
          (<button className="content__gallery_more" onClick={showMoreCardsAzan}>Show more</button>)
        }
      </div>

      <h2 className='content__title_second'>Gallery { userLang ? "Projets" : "Projects" }</h2>
      <div className='content__gallery'>
        <ul className='content__gallery_element'>
          {
            <Gallery
              album={props.albumProject}
              count={counterProj}
              name="proj-com"
              handleCardClick={(card) => props.handleCardClick(card)}
            />
            }
        </ul>
        {
          counterProj < props.albumProject?.length &&
          (<button className="content__gallery_more" onClick={showMoreCardsProj}>Show more</button>)
        }
      </div>

      <h2 className='content__title_second'>Gallery Sports</h2>
      <div className='content__gallery'>
        <ul className='content__gallery_element'>
          {
            <Gallery
              album={props.albumSport}
              count={counterSport}
              name="sport"
              handleCardClick={(card) => props.handleCardClick(card)}
            />
          }
        </ul>
        {
          counterSport < props.albumSport?.length &&
          (<button className="content__gallery_more" onClick={showMoreCardsSport}>Show more</button>)
        }
      </div>

    </section>
  )
}

export default Activities;