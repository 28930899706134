import React from "react";
import Logo from '../Logo/Logo';
import NavList from "../NavList/NavList";
import langIcon from '../../images/logos/lang_picker.svg';

import './Header.css';

function Header(props) {
  const commons_french = require('../../translations/common_text_fr.json');
  const commons_english = require('../../translations/common_text_en.json');
  const userLang = props.language === "french";
  let list = document.getElementById("languages");


  function langButtonClick() {
    document.getElementById("tooltip").style.display = "none";
    list.style.visibility = "visible";
    list.style.display = "flex";
  }

  function langChange(language) {
    props.handleSetLanguage(language)
    list.style.display = "none"
  }


  return (
    <header
      className="header"
    >
      <div className="header__menus">
        <Logo alt="header" />
        <div className="header__menus_details">
          <div className="header__language">
            <span id="tooltip" className="lang-tooltip">Choose language here!</span>
            <img src={langIcon} alt="lang-icon" onClick={langButtonClick} className="header__language_icon" />

            <ul id="languages" className="header__language_list">
              <li id="english" onClick={(evt) => langChange(evt.currentTarget.id)} className="header__language_option">English</li>
              <li id="french" onClick={(evt) => langChange(evt.currentTarget.id)} className="header__language_option">French</li>
            </ul>
          </div>
          <NavList language={props.language} />
        </div>
      </div>
      <h1 className="header__text">
        { userLang ? commons_french.header.main_text : commons_english.header.main_text }
      </h1>
    </header>
  )
}

export default Header;