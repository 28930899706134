import React from 'react';

function ImagePopup(card) {
  return (
    <div className={`popup popup_image ${card.isOpen ? "popup_open" : ""}`} onClick={card.onClose}>
      <div className="popup__content">
        <button type="button" aria-label="close-expanded-image" className="popup__close" onClick={card.onClose}></button>
        <img  className="popup__image" src={card.src} alt={card.alt} />
      </div>
    </div>
  )
}

export default ImagePopup;