import React from "react";
import Gallery from "../Gallery/Gallery";
import flyerFrench from "../../images/flyer_fr.svg";
import flyerEnglish from "../../images/flyer_en.png";

import './CHEED.css';


function CHEED(props) {
  const [gallery, setGalleryName] = React.useState("Photo");
  const [displayGallery, setGalleryDisplay] = React.useState(false);
  const commons_french = require('../../translations/common_text_fr.json');
  const commons_english = require('../../translations/common_text_en.json');
  const userLang = props.language === "french";
  const counter = props.imageCount

  function handleCardClick(name) {
    setGalleryDisplay(true);
    setGalleryName(name);
    props.resetImageCounter();
  }


  return (
    <section className='centre content__section'>
      <div className='centre__accueil'>
        <img src="https://i.imgur.com/jiYbSKt.png" alt='centre-plak' className='content__image' />

        <p className='content__paragraph'>
          { userLang ? commons_french.cheed.paragraph_text_1 : commons_english.cheed.paragraph_text_1 } <span className="content__paragraph-span">Centre d'Hébergement et d'Education des Enfants Démunis </span>
          { userLang ? commons_french.cheed.paragraph_text_2 : commons_english.cheed.paragraph_text_2 } <span className="content__paragraph-span_abbr">CH2ED</span>
          { userLang ? commons_french.cheed.paragraph_text_3 : commons_english.cheed.paragraph_text_3 }
          <span className="content__paragraph-span_abbr">CH2ED</span> { userLang ? commons_french.cheed.paragraph_text_4 : commons_english.cheed.paragraph_text_4 }
          <span className="content__paragraph-span_abbr">CH2ED</span> { userLang ? commons_french.cheed.paragraph_text_5 : commons_english.cheed.paragraph_text_5 }
          <i>{ userLang ? commons_french.cheed.paragraph_text_6 : commons_english.cheed.paragraph_text_6 }</i>
        </p>
      </div>

      <div className='gallery__photos'>
        { (!displayGallery && <h3 className="content__title">Gallery</h3>) }

        <ul className='cards'>
          <li className='cards__item' onClick={() => handleCardClick("2017") }>
            2017
            <img src={props.albumCheed17[3]} alt='cover-2017' className='cards__photo' />
          </li>
          <li className='cards__item' onClick={() => handleCardClick("2019") }>
            2019
            <img src={props.albumCheed19[4]} alt='cover-2019' className='cards__photo' />
          </li>
          <li className='cards__item' onClick={() => handleCardClick("2020") }>
            2020
            <img src={props.albumCheed20[1]} alt='cover-2020' className='cards__photo' />
          </li>
          <li className='cards__item' onClick={() => handleCardClick("2022") }>
            2022
            <img src={props.albumCheed22[1]} alt='cover-2022' className='cards__photo' />
          </li>
          <li className='cards__item' onClick={() => handleCardClick("Archive") }>
            Archive
            <img src={props.albumCheedArch[1]} alt='cover-arch' className='cards__photo' />
          </li>
          <li className='cards__item'onClick={() => handleCardClick("Donations") }>
            Donations
            <img src={props.albumCheedDon[4]} alt='cover-don' className='cards__photo' />
          </li>
        </ul>

        { displayGallery ? <h2 className='content__title_second'>Gallery - {gallery}</h2> : null }

        {
          (gallery === "2017") && displayGallery
          ? <>
              <Gallery
                album={props.albumCheed17}
                count={counter}
                name="ch2017"
                handleCardClick={(card) => props.handleCardClick(card)}
                />
              {
                counter < props.albumCheed17?.length &&
                (<button className="content__gallery_more" onClick={props.showMoreCards}>Show more</button>)
              }
            </>
          : null
        }

        {
          (gallery === "2019") && displayGallery
          ? <>
              <Gallery
                album={props.albumCheed19}
                count={counter}
                name="ch2019"
                handleCardClick={(card) => props.handleCardClick(card)}
                />
              {
                counter < props.albumCheed19?.length &&
                (<button className="content__gallery_more" onClick={props.showMoreCards}>Show more</button>)
              }
            </>
          : null
        }

        {
          (gallery === "2020") && displayGallery
          ? <>
              <Gallery
                album={props.albumCheed20}
                count={counter}
                name="ch2020"
                handleCardClick={(card) => props.handleCardClick(card)}
                />
              {
                counter < props.albumCheed20?.length &&
                (<button className="content__gallery_more" onClick={props.showMoreCards}>Show more</button>)
              }
            </>
          : null
        }

        {
          (gallery === "2022") && displayGallery
          ? <>
              <Gallery
                album={props.albumCheed22}
                count={counter}
                name="ch2022"
                handleCardClick={(card) => props.handleCardClick(card)}
                />
              {
                counter < props.albumCheed22?.length &&
                (<button className="content__gallery_more" onClick={props.showMoreCards}>Show more</button>)
              }
            </>
          : null
        }

        {
          (gallery === "Archive") && displayGallery
          ? <>
              <Gallery
                album={props.albumCheedArch}
                count={counter}
                name="ch-arch"
                handleCardClick={(card) => props.handleCardClick(card)}
                />
              {
                counter < props.albumCheedArch?.length &&
                (<button className="content__gallery_more" onClick={props.showMoreCards}>Show more</button>)
              }
            </>
          : null
        }

        {
          (gallery === "Donations") && displayGallery
          ? <>
              <Gallery
                album={props.albumCheedDon}
                count={counter}
                name="ch-don"
                handleCardClick={(card) => props.handleCardClick(card)}
              />
              {
                counter < props.albumCheedDon?.length &&
                (<button className="content__gallery_more" onClick={props.showMoreCards}>Show more</button>)
              }
            </>
          : null
        }
      </div>

      { userLang
        ? <div className="centre__fundarising">
            <h3 className="content__title">Levée de Fonds</h3>
            <img src={flyerFrench} alt="flyer-fr" />
          </div>
        : <div className="centre__fundarising">
            <h3 className="content__title">Fundraising</h3>
            <img src={flyerEnglish} alt="flyer-en" />
          </div>
      }
      <div className="centre__contact">
        <h3 className="content__title">Contacts</h3>
        <p className="content__paragraph">
          { userLang ? commons_french.cheed.paragraph_text_7 : commons_english.cheed.paragraph_text_7 }
        </p>
        <p className="content__paragraph">
          <b>{ userLang ? commons_french.cheed.paragraph_text_8 : commons_english.cheed.paragraph_text_8 }</b>
        </p>
        <table className="centre__contact_content">
          <tbody>
            <tr>
              <td className="centre__contact_name">Fiato Komi Tekpa</td>
              <td className="centre__contact_phone">
                <a href="tel:+22890047116" className="centre__contact_phone-number">(+228) 90 04 71 16</a>
              </td>
            </tr>
            <tr>
              <td className="centre__contact_name">Mama Kediasso II</td>
              <td className="centre__contact_phone">
                <a href="tel:+22890200454" className="centre__contact_phone-number">(+228) 90 20 04 54</a>
              </td>
            </tr>
            <tr>
              <td className="centre__contact_name">Agbota Kokou Agbigbi</td>
              <td className="centre__contact_phone">
                <a href="tel:+22890176927" className="centre__contact_phone-number">(+228) 90 17 69 27</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <div className="centre__projet">
        insert pdf view of the project here
      </div> */}
    </section>
  )
}

export default CHEED;